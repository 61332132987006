import React from "react";
import "@styles/index.scss";
import SEO from "../components/Seo";
import { useI18next, useTranslation } from "gatsby-plugin-react-i18next";
import Navbar from "@organism/Navbar/Navbar";
import Footer from "@organism/Footer/Footer";
import PrivacyPolicy from "@organism/PrivacyPolicy";


const PrivacyPolicyPage = ({ location }) => {
  const { t } = useTranslation();
  const { language } = useI18next();
  
  return (
    <>
      <SEO
        title={t('SEO.PRIVACY_POLICY.TITLE')}
        description={t('SEO.PRIVACY_POLICY.DESCRIPTION')}
        originUrl={location.origin}
        siteUrl={location.href}
        lang={language}
      />
      <Navbar location={location} />
      <PrivacyPolicy />
      <Footer />
    </>
  );
};

export default PrivacyPolicyPage;