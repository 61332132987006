import React from "react";
import styles from "./privacy-policy.module.scss";
import { useTranslation } from "gatsby-plugin-react-i18next";

const PrivacyPolicy = () => {
  const { t } = useTranslation();

  return (
    <section className={styles.privacy_section}>
      <div className='col-md-12'>
        <div className='row' style={{ justifyContent: "center", paddingBottom: "15px", paddingTop: "25px" }}>
          <p
            className='fs26'
            style={{
              textAlign: "center",
            }}
          >
            {t("PRIVACY_POLICY.INFORMATION.TITLE")}
          </p>
        </div>
        <div className='row' style={{ justifyContent: "center" }}>
          <div>
            <p
              className='fs18'
              style={{
                textAlign: "justify",
              }}
            >
              {t("PRIVACY_POLICY.INFORMATION.SUBTITLE")}
            </p>
          </div>
        </div>
      </div>

      <div className='col-md-12'>
        <div className='row' style={{ justifyContent: "center", paddingBottom: "15px", paddingTop: "25px" }}>
          <p
            className='fs26'
            style={{
              textAlign: "justify",
            }}
          >
            {t("PRIVACY_POLICY.PRIVACY.TITLE")}
          </p>
        </div>
        <div className='row' style={{ justifyContent: "center" }}>
          <div>
            <p
              className='fs18'
              style={{
                textAlign: "justify",
              }}
            >
              {t("PRIVACY_POLICY.PRIVACY.SUBTITLE1")}
            </p>
            <p
              className='fs18'
              style={{
                textAlign: "justify",
              }}
            >
              {t("PRIVACY_POLICY.PRIVACY.SUBTITLE2")}
            </p>
            <p
              className='fs18'
              style={{
                textAlign: "justify",
              }}
            >
              {t("PRIVACY_POLICY.PRIVACY.SUBTITLE3")}
            </p>
          </div>
        </div>
      </div>

      <div className='col-md-12'>
        <div className='row' style={{ justifyContent: "center", paddingBottom: "15px", paddingTop: "25px" }}>
          <p
            className='fs26'
            style={{
              textAlign: "justify",
            }}
          >
            {t("PRIVACY_POLICY.REQUESTED_INFORMATION.TITLE")}
          </p>
        </div>
        <div className='row' style={{ justifyContent: "center" }}>
          <div>
            <p
              className='fs18'
              style={{
                textAlign: "justify",
              }}
            >
              {t("PRIVACY_POLICY.REQUESTED_INFORMATION.SUBTITLE1")}
            </p>
            <p
              className='fs18'
              style={{
                textAlign: "justify",
              }}
            >
              {t("PRIVACY_POLICY.REQUESTED_INFORMATION.SUBTITLE2")}
            </p>
            <p
              className='fs18'
              style={{
                textAlign: "justify",
              }}
            >
              {t("PRIVACY_POLICY.REQUESTED_INFORMATION.SUBTITLE3")}
            </p>
             <p
              className='fs18'
              style={{
                textAlign: "justify",
              }}
            >
              {t("PRIVACY_POLICY.REQUESTED_INFORMATION.SUBTITLE4")}
            </p>
             <p
              className='fs18'
              style={{
                textAlign: "justify",
              }}
            >
              {t("PRIVACY_POLICY.REQUESTED_INFORMATION.SUBTITLE5")}
            </p>
             <p
              className='fs18'
              style={{
                textAlign: "justify",
              }}
            >
              {t("PRIVACY_POLICY.REQUESTED_INFORMATION.SUBTITLE6")}
            </p>
          </div>
        </div>
      </div>

      <div className='col-md-12'>
        <div className='row' style={{ justifyContent: "center", paddingBottom: "15px", paddingTop: "25px" }}>
          <p
            className='fs26'
            style={{
              textAlign: "justify",
            }}
          >
            {t("PRIVACY_POLICY.USER_RIGHTS.TITLE")}
          </p>
        </div>
        <div className='row' style={{ justifyContent: "center" }}>
          <div>
            <p
              className='fs18'
              style={{
                textAlign: "justify",
              }}
            >
              {t("PRIVACY_POLICY.USER_RIGHTS.SUBTITLE1")}
            </p>
            <p
              className='fs18'
              style={{
                textAlign: "justify",
              }}
            >
              {t("PRIVACY_POLICY.USER_RIGHTS.SUBTITLE2")}
            </p>
          </div>
        </div>
      </div>

      <div className='col-md-12'>
        <div className='row' style={{ justifyContent: "center", paddingBottom: "15px", paddingTop: "25px" }}>
          <p
            className='fs26'
            style={{
              textAlign: "justify",
            }}
          >
            {t("PRIVACY_POLICY.SECURITY.TITLE")}
          </p>
        </div>
        <div className='row' style={{ justifyContent: "center" }}>
          <div>
            <p
              className='fs18'
              style={{
                textAlign: "justify",
              }}
            >
              {t("PRIVACY_POLICY.SECURITY.SUBTITLE1")}
            </p>
            <p
              className='fs18'
              style={{
                textAlign: "justify",
              }}
            >
              {t("PRIVACY_POLICY.SECURITY.SUBTITLE2")}
            </p>
            <p
              className='fs18'
              style={{
                textAlign: "justify",
              }}
            >
              {t("PRIVACY_POLICY.SECURITY.SUBTITLE3")}
            </p>
          </div>
        </div>
      </div>

      <div className='col-md-12'>
        <div className='row' style={{ justifyContent: "center", paddingBottom: "15px", paddingTop: "25px" }}>
          <p
            className='fs26'
            style={{
              textAlign: "justify",
            }}
          >
            {t("PRIVACY_POLICY.PERSONAL_DATA.TITLE")}
          </p>
        </div>
        <div className='row' style={{ justifyContent: "center" }}>
          <div>
            <p
              className='fs18'
              style={{
                textAlign: "justify",
              }}
            >
              {t("PRIVACY_POLICY.PERSONAL_DATA.SUBTITLE1")}
            </p>
            <p
              className='fs18'
              style={{
                textAlign: "justify",
              }}
            >
              {t("PRIVACY_POLICY.PERSONAL_DATA.SUBTITLE2")}
            </p>
          </div>
        </div>
      </div>

      <div className='col-md-12'>
        <div className='row' style={{ justifyContent: "center", paddingBottom: "15px", paddingTop: "25px" }}>
          <p
            className='fs26'
            style={{
              textAlign: "justify",
            }}
          >
            {t("PRIVACY_POLICY.SAVED_DATA.TITLE")}
          </p>
        </div>
        <div className='row' style={{ justifyContent: "center" }}>
          <div>
            <p
              className='fs18'
              style={{
                textAlign: "justify",
              }}
            >
              {t("PRIVACY_POLICY.SAVED_DATA.SUBTITLE1")}
            </p>
            <p
              className='fs18'
              style={{
                textAlign: "justify",
              }}
            >
              {t("PRIVACY_POLICY.SAVED_DATA.SUBTITLE2")}
            </p>
          </div>
        </div>
      </div>

      <div className='col-md-12'>
        <div className='row' style={{ justifyContent: "center", paddingBottom: "15px", paddingTop: "25px" }}>
          <p
            className='fs26'
            style={{
              textAlign: "justify",
            }}
          >
            {t("PRIVACY_POLICY.SITE_USES.TITLE")}
          </p>
        </div>
        <div className='row' style={{ justifyContent: "center" }}>
          <div>
            <p
              className='fs18'
              style={{
                textAlign: "justify",
              }}
            >
              {t("PRIVACY_POLICY.SITE_USES.SUBTITLE")}
            </p>
          </div>
        </div>
      </div>

      <div className='col-md-12'>
        <div className='row' style={{ justifyContent: "center", paddingBottom: "15px", paddingTop: "25px" }}>
          <p
            className='fs26'
            style={{
              textAlign: "justify",
            }}
          >
            {t("PRIVACY_POLICY.DATA_PROTECTION.TITLE")}
          </p>
        </div>
        <div className='row' style={{ justifyContent: "center" }}>
          <div>
            <p
              className='fs18'
              style={{
                textAlign: "justify",
              }}
            >
              {t("PRIVACY_POLICY.DATA_PROTECTION.SUBTITLE")}
            </p>
          </div>
        </div>
      </div>

      <div className='col-md-12'>
        <div className='row' style={{ justifyContent: "center", paddingBottom: "15px", paddingTop: "25px" }}>
          <p
            className='fs26'
            style={{
              textAlign: "justify",
            }}
          >
            {t("PRIVACY_POLICY.KIDS.TITLE")}
          </p>
        </div>
        <div className='row' style={{ justifyContent: "center" }}>
          <div>
            <p
              className='fs18'
              style={{
                textAlign: "justify",
              }}
            >
              {t("PRIVACY_POLICY.KIDS.SUBTITLE")}
            </p>
          </div>
        </div>
      </div>

      <div className='col-md-12'>
        <div className='row' style={{ justifyContent: "center", paddingBottom: "15px", paddingTop: "25px" }}>
          <p
            className='fs26'
            style={{
              textAlign: "justify",
            }}
          >
            {t("PRIVACY_POLICY.PRIVACY_POLICY_UPDATE.TITLE")}
          </p>
        </div>
        <div className='row' style={{ justifyContent: "center" }}>
          <div>
            <p
              className='fs18'
              style={{
                textAlign: "justify",
              }}
            >
              {t("PRIVACY_POLICY.PRIVACY_POLICY_UPDATE.SUBTITLE")}
            </p>
          </div>
        </div>
      </div>

      <div className='col-md-12'>
        <div className='row' style={{ justifyContent: "center", paddingBottom: "15px", paddingTop: "25px" }}>
          <p
            className='fs26'
            style={{
              textAlign: "justify",
            }}
          >
            {t("PRIVACY_POLICY.LEGACY.TITLE")}
          </p>
        </div>
        <div className='row' style={{ justifyContent: "center" }}>
          <div>
            <p
              className='fs18'
              style={{
                textAlign: "justify",
              }}
            >
              {t("PRIVACY_POLICY.LEGACY.SUBTITLE")}
            </p>
          </div>
        </div>
      </div>

      <div className='col-md-12'>
        <div className='row' style={{ justifyContent: "center", paddingBottom: "15px", paddingTop: "25px" }}>
          <p
            className='fs26'
            style={{
              textAlign: "justify",
            }}
          >
            {t("PRIVACY_POLICY.QUESTIONS.TITLE")}
          </p>
        </div>
        <div className='row' style={{ justifyContent: "center" }}>
          <div>
            <p
              className='fs18'
              style={{
                textAlign: "justify",
              }}
            >
              {t("PRIVACY_POLICY.QUESTIONS.SUBTITLE")}
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PrivacyPolicy;